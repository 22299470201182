@import 'src/scss';

.quiz-container {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: -99;

        img {
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        position: fixed;
        object-fit: cover;
        z-index: -100;
        @include fadeIn(2s, alternate);
    }    
}

@media (max-width: $mobile-screen) {
    .quiz-container {
        height: 80vh;
    }
}