// COLORS

// Grayscale
$black: #0d0504 !default;
$gray-300: #40281a !default;
$gray-200: #6e543d !default;
$gray-100: #e8b961 !default;
$white: #cd993a !default;

// Brand
$primary: #664912 !default;
$secondary: #665536 !default;
$tertiary: #ada28c !default;
$warning: #edb407 !default;
$danger: #66520e !default;

// TYPOGRAPHY
$tiny: 0.8rem;
$small: 0.9rem;
$medium: 1rem;
$large: 1.1rem;
$big: 1.2rem;
$subheadline: 1.5rem;
$headline: 2rem;
$subtitle: 2.5rem;
$title: 3rem;
$largeTitle: 4rem;

// MEDIAS
$smaller-screen: 30rem;
$mobile-screen: 40rem;
$medium-screen: 45rem;
$large-screen: 55rem;
$xLarge-screen: 70rem;
$largest-screen: 80rem;

