@import 'src/scss';

.chart-wrapper {    
    display: flex;
    flex-direction: row;
    background-color: $white;
    justify-content: center; 
    margin: auto;
    width: 65%;
    gap: 1.5rem;
    @include fadeIn(1s, alternate);

    .single-chart {
        @extend %centered-column;
        text-align: center;    
        width: 25rem; 
            
      .circular-chart {
        height: 15rem;
        display: block;
        margin: 0 auto;
      
        .circle {
            fill: none;
            stroke-width: 4;
            animation: progress 1s ease-out forwards;
        }

        .resource {
          stroke: $secondary;
        }

        .energy {
          stroke: $primary;
        }
      
        .percentage {
            fill: $gray-200;
            font-size: 0.5em;
            text-anchor: middle;
            top: 50%;
            margin-right: 100%;
            @include fadeIn(2s, forwards);
        }
      }         

      p {
        padding-top: 1rem;
        font-size: $medium;
        color: $gray-200;
      }
    }    
}
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  @media screen and (max-width: $mobile-screen) {
    .chart-wrapper {    
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      .single-chart {
        width: 100%; 
      }
    }
  }