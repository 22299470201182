@import "src/scss";

.newsletter {
	display: flex;
	height: 30rem;
	width: 100%;
	flex-direction: column;
	background: url("../../assets/photos/newsletter.png") no-repeat center
		center;
	background-size: cover;
	position: relative;
	.subscribe-container.submitted {
		padding: 4rem 3rem;
	}
	.subscribe-container {
		width: 35rem;
		right: 3rem;
		top: 50%;
		transform: translate(0, -50%);
		text-align: center;
		margin: 0 auto 0;
		padding: 4rem 5rem;
		position: absolute;
		background-color: $gray-100;

		.subscribe-column {
			display: flex;
			flex-direction: column;

			i {
				color: $secondary;
				padding-bottom: 1rem;
			}

			h1 {
				font-size: $headline;
				color: $primary;

				br {
					display: none;
				}
			}

			p {
				font-size: $big;
				padding-top: 1rem;
				padding-bottom: 1rem;
			}
		}

		.subscribe-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			position: relative;
			gap: 1rem;

			.input {
				@include form-component(static);
				p {
					position: absolute;
				}
			}

			button {
				min-width: 8rem;
				margin-bottom: 0.5rem;
			}
		}
	}
}

@media screen and (max-width: $medium-screen) {
	.newsletter {
		height: 30rem;
		margin: auto;
		justify-content: center;
		box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);

		.subscribe-container.submitted {
			padding: 4rem 2rem;
		}

		.subscribe-container {
			width: 80%;
			right: 50%;
			transform: translate(50%, -50%);
			padding: 4rem 0;
			background-color: transparent;

			.subscribe-column {
				h1 {
					color: $secondary;

					span {
						display: none;
					}

					br {
						display: inline;
					}
				}

				p {
					color: $white;
					font-size: $large;
				}
			}

			.subscribe-row {
				flex-direction: column;
				gap: 0;
				.input {
					p {
						position: relative;
					}
				}
			}
		}
	}
}

