@import 'src/scss';

.grid {
    display: block;
    background-color: $white;
    justify-content: stretch;
    width: 100%;

    .container {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        background-color: $white;

        figure {
            width: 100%;
            overflow: hidden;
            cursor: pointer;
        }
        
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            transition: all 0.2s linear;
            
            &:hover {
                transform: scale(1.1);
            }
        }
    }    
}

@media screen and (max-width: $mobile-screen) {
    .grid {
        .container {
            grid-template-columns: repeat(2, 1fr);
        }
    }

}
