@import 'src/scss';

.card-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 2rem auto 2rem;
    width: 100%;
    background-color: $gray-100;
    @include fadeIn(1s, alternate);

    .content {
        @extend %banner-content;
    }
}

@media screen and (max-width: $large-screen) {   
    .card-container {
        margin: 0 auto 2rem;

        .content {
            padding: 3.5rem;
        }
    }    
  }