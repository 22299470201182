@import 'src/scss';

.app {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 20rem;
    margin: 6rem auto 0;
    text-align: center;
    background-color: $primary;
    border-radius: 1.5rem;
  
    figure {
        width: 35%;
        display: block;
        position: relative;
        align-items: center;
        justify-content: center;
        background-size: cover;

        img {
            position: absolute;
            max-height: 120%;
            left: 3rem;
            bottom: 0;
            z-index: 50;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 65%;
        padding: 4rem 4rem 2rem;
        color: $white;
    
        h1 {
            font-size: $headline;
        }
        
        p {
            font-size: $big;
            padding-top: 1rem;
            padding-bottom: 2rem;
        }

        link {
            img {
                margin-bottom: 1rem;
                height: 3.7rem;
                cursor: pointer;
            }
        }      
    }
}
  
@media screen and (max-width: $large-screen) {
    .app {
        margin: 2rem auto 2rem;

        figure {
            display: none;
        }

        .content {
            width: 100%;
        }
    }
}

@media screen and (max-width: $xLarge-screen) {
    .app {
        height: 100%;
        
        .content {
            padding: 3rem 4rem 2rem;
        }
    }
}
  