@import 'src/scss';

.success-message {
    @extend %brown-container;
    padding: 2.5rem;
    text-align: center;
    max-width: 65%;

    h2 {
        font-size: $headline;
        margin: 1.5rem auto 1.5rem;
        color: $white;
      }

    p {
        font-size: $big;
        padding-bottom: 0.5rem;
        color: $tertiary;
    }

    i{
      color: $white;
    }

}

@media screen and (max-width: $mobile-screen) {
  .success-message {
    padding: 2.5rem 1.5rem;
    max-width: 75%;
}

  .success-message h2 {
    font-size: $headline;
  }
}