@import "src/scss";

.about {
	background-color: $white;

	.cover {
		height: 100vh;
		justify-content: center;
		position: relative;

		.story-photo {
			height: 100%;
			@include background-cover("../assets/photos/about-cover.png");
		}

		.team-photo {
			height: 100%;
			@include background-cover("../assets/photos/cups-cover.jpg");
		}

		.bar {
			display: flex;
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			border-bottom: solid 0.25rem $primary;
			height: 4.5rem;
			text-align: center;
			align-items: center;
			justify-content: center;
			background-color: $primary;
			color: $white;

			h2 {
				font-size: $subheadline;

				br {
					display: none;
				}
			}
		}
	}

	.content {
		@extend %centered-content;
		background-color: $white;

		p {
			font-size: $big;
			padding-bottom: 1rem;

			&:last-child {
				padding-bottom: 0;
			}
		}

		a {
			color: $primary;
			text-decoration: none;
			transition: 0.5s;
			&:hover {
				color: $secondary;
			}
		}
	}

	.gallery {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(4, 1fr);
		justify-items: center;
		padding: 0 2rem 2rem;
		gap: 2rem;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include fadeIn(1s, alternate);

			&:nth-child(2) {
				grid-column: 2 / 4;
			}
		}
	}
}

@media (max-width: $mobile-screen) {
	.about {
		.cover {
			height: 60vh;
			.bar {
				height: 7rem;
				padding: 1rem 2rem 1rem;

				h2 {
					span {
						display: none;
					}

					br {
						display: inline;
					}
				}
			}
		}

		.content {
			max-width: 75%;
		}

		.gallery {
			padding: 0;
			gap: 0;
		}
	}
}
