@import 'src/scss';

.menu {
    margin: 4.5rem 0 0;

    .loading-container {
        @extend %loading-container;
    }
  
    .cover {
        width: 100%;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
        object-fit: cover;
        background-size: cover;
  
        video {
            object-fit: cover;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            position: fixed;
            z-index: -10;
        }

        .content {
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 2.5rem 2.5rem 3.5rem;
            color: $white;

                h1 {
                    font-size: $largeTitle;
                }
                
                h2 {
                    font-size: $subheadline;
                    padding-top: 1.5rem;
                    line-height: 2.2rem;
                }
        }
    }

    .banner-wrapper {
        height: 100%;
        background-color: $white;
    }
}
  
@media screen and (max-width: $mobile-screen) {
    .menu {
        .cover {
            .content {
                padding: 3rem 4rem 3rem;

                h1 {
                    font-size: $title;
                }

                h2 {
                    display: none;
                }

                br {
                    display: none;
                }
            }
            }
    }
}