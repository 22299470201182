@import "variables";
@import "mixins";
@import "extends";

:root {
	overflow-x: hidden;
	background-color: $white !important;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Mulish", sans-serif;
}

::selection {
	color: $white;
	background: $primary;
}

