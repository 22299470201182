@import 'src/scss';

.menu-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;

    .content {
        h1 {
            font-size: $subtitle;
            color: $primary;

            &:after {
                content: "";
                display: block;
                left: 0;
                height: 0.1rem;
                min-width: 100%;
                background-color: $secondary;
                margin: 1.2rem 0rem 1.5rem;
            }
        }
  
        .card-item {
            display: flex;
            padding-bottom: 1rem;

            h2 {
                align-self: center;
                padding-right: 1rem;
                font-size: $big;                
                @include fadeIn(1s, alternate);
            }

            p {
                align-self: center;
                padding-top: 0.2rem;
                padding-right: 2rem;
                color: $secondary;
                @include fadeIn(1s, alternate);
            }
        }
    }

    .image {                    
        width: 17rem;
        height: 17rem;
        background-color: $white;

        div {
            img {
                max-width: 100%;
                max-height: 100%;
                transition: 0.5s;
                transition-duration: 1s linear; 
                @include fadeIn(.7s, alternate);
            }
        }
    }
}

@media screen and (max-width: $largest-screen) {
    .menu-card {
        display: block;
        padding: auto;

        .content {
            .card-item {
                p {
                    padding-right: 0rem;
                }
            }
        }

        .image {
            display: none;
        }
    }
}

@media screen and (max-width: 60rem) {
    .menu-card {
        .content {
            .card-item {
                display: block;
            }
        }
    }
}

@media screen and (max-width: $mobile-screen) {
    .menu-card {

        .image {            
            display: block;               
            width: 98%;
            height: 98%;
            margin: 1rem 0 0;

            div {    
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}