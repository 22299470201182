@import 'src/scss';

.main-menu {
    width: 100%;
    display: flex;
    background-color: $white;
  
    .menu-list {
        min-width: 17rem;
        height: 100%;
        margin: 2rem;
        background-color: $primary;
        border-radius: 0.5rem;
    
        .nav-menu {
            font-size: $big;
            font-weight: bolder;
            padding: 0.5rem 1.5rem 2rem;
            transition: 0.2s;
        }
    }
}
  
  @media screen and (max-width: xLarge-screen) {
    .menu-list {
      min-width: 15rem;
      margin: 2rem 0 0 2rem;
    }
    .nav-menu {
      font-size: $large;
      font-weight: bolder;
      padding: 0.5rem 1.3rem 2rem;
      transition: 0.2s;
    }
  }
  
  @media screen and (max-width: $mobile-screen) {
    .main-menu {
      display: block;
      padding-top: 2rem;
  
        .menu-list {
            min-width: 15rem;
            margin: 0 2rem 0;
        }
    }
  }
  